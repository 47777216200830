/* eslint-disable no-param-reassign */

import {
    GeometryInstance,
    RectangleGeometry,
    Rectangle,
    Primitive,
    MaterialAppearance,
    Material,
    Color,
} from '@cesium/engine';
import { useCallback } from 'react';

export function useBoundingBox(
    cesiumWidget: any,
    flyToInProgress: React.MutableRefObject<boolean>,
) {
    const flyToRectangle = useCallback(
        (object: any) => {
            const rectangleGeometry = new GeometryInstance({
                geometry: new RectangleGeometry({
                    rectangle: Rectangle.fromDegrees(
                        object.geojson_bounding_box!.coordinates[0][0][0],
                        object.geojson_bounding_box!.coordinates[0][0][1],
                        object.geojson_bounding_box!.coordinates[0][1][0],
                        object.geojson_bounding_box!.coordinates[0][2][1],
                    ),
                    height: 0,
                }),
            });

            // Create primitive with the rectangle geometry
            const rectanglePrimitive = new Primitive({
                geometryInstances: rectangleGeometry,
                appearance: new MaterialAppearance({
                    material: Material.fromType('Color', {
                        color: Color.BLUE.withAlpha(0.2),
                    }),
                    faceForward: true,
                }),
            });

            const buffer = 0.1; // Adjust this value to control the amount of zoom out

            const west = object.geojson_bounding_box!.coordinates[0][0][0] - buffer;
            const south = object.geojson_bounding_box!.coordinates[0][0][1] - buffer;
            const east = object.geojson_bounding_box!.coordinates[0][1][0] + buffer;
            const north = object.geojson_bounding_box!.coordinates[0][2][1] + buffer;

            flyToInProgress.current = true;

            // Zoom to the rectangle
            cesiumWidget.camera.flyTo({
                destination: Rectangle.fromDegrees(west, south, east, north),
                complete: () => {
                    flyToInProgress.current = false;
                },
            });
            cesiumWidget.scene.primitives.add(rectanglePrimitive);
        },
        [cesiumWidget, flyToInProgress],
    );

    return flyToRectangle;
}
