import {
    ScreenSpaceEventHandler,
    ScreenSpaceEventType,
    Label,
    PointPrimitive,
    LabelCollection,
    PointPrimitiveCollection,
} from '@cesium/engine';
import { useEffect } from 'react';

export function useHandlePrimitiveClick(
    cesiumWidget: any,
    resetClusters: () => void,
    setSelectedCluster: (cluster: any) => void,
    myClusters: any[],
) {
    useEffect(() => {
        if (!cesiumWidget) return;

        const handler = new ScreenSpaceEventHandler(cesiumWidget.scene.canvas);

        handler.setInputAction((click: any) => {
            resetClusters();
            const pickedObject = cesiumWidget.scene.pick(click.position);
            if (
                pickedObject.primitive instanceof Label ||
                pickedObject.primitive instanceof PointPrimitive
            ) {
                const foundID = pickedObject.primitive.id;

                for (let i = 0; i < cesiumWidget.scene.primitives.length; i += 1) {
                    const primitive = cesiumWidget.scene.primitives.get(i);

                    if (primitive instanceof LabelCollection) {
                        for (let j = 0; j < primitive.length; j += 1) {
                            const label = primitive.get(j) as Label;
                            if (label.id === foundID) {
                                if (Number(label.text) <= 7) {
                                    label.show = false;
                                    setSelectedCluster(
                                        myClusters.find(
                                            (cluster) => cluster.id === pickedObject.primitive.id,
                                        ),
                                    );
                                }
                            }
                        }
                    } else if (primitive instanceof PointPrimitiveCollection) {
                        for (let j = 0; j < primitive.length; j += 1) {
                            const point = primitive.get(j) as PointPrimitive;
                            if (point.id === foundID) {
                                // Find the corresponding label to check its text
                                let labelText = '';
                                for (let k = 0; k < cesiumWidget.scene.primitives.length; k += 1) {
                                    const labelPrimitive = cesiumWidget.scene.primitives.get(k);
                                    if (labelPrimitive instanceof LabelCollection) {
                                        for (let l = 0; l < labelPrimitive.length; l += 1) {
                                            const label = labelPrimitive.get(l) as Label;
                                            if (label.id === foundID) {
                                                labelText = label.text;
                                                break;
                                            }
                                        }
                                    }
                                    if (labelText) break;
                                }
                                if (Number(labelText) <= 7) {
                                    point.show = false;
                                    setSelectedCluster(
                                        myClusters.find(
                                            (cluster) => cluster.id === pickedObject.primitive.id,
                                        ),
                                    );
                                }
                            }
                        }
                    }
                }
            }
        }, ScreenSpaceEventType.LEFT_CLICK);

        // eslint-disable-next-line consistent-return
        return () => {
            handler.destroy();
        };
    }, [cesiumWidget, setSelectedCluster, myClusters]);
}
