import type { CesiumWidget } from '@cesium/engine';
import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { CesiumMap } from '@local/map-viewer/dist/cesium/CesiumMap';
import { CesiumSearch } from '@local/map-viewer/dist/cesium/CesiumSearch/CesiumSearch';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import { useEffect, useState } from 'react';

import { searchBreadcrumb } from 'src/utils/breadcrumbs';

import { MetadataPanel } from './cesium/Menus/metadataPanel/MetadataPanel';
import { ResultsPanel } from './cesium/Menus/resultsPanel/ResultsPanel';
import { ObjectPoint } from './cesium/Points/ObjectPoint';

export const CesiumSearchPage = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [{ name: searchBreadcrumb.name }];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);
    setDocumentTitle('Search');

    return (
        <CesiumMap
            customComponents={(cesiumWidget: CesiumWidget) => [
                <>
                    <ObjectPoint cesiumWidget={cesiumWidget} />

                    <div style={{ position: 'absolute', top: 10 }}>
                        <div style={{ position: 'relative' }}>
                            <CesiumSearch
                                query={searchQuery}
                                setQuery={setSearchQuery}
                                scene={cesiumWidget.scene}
                                camera={cesiumWidget.camera}
                                style={{ position: 'relative' }}
                            />
                            <div style={{ paddingLeft: 20 }}>
                                <ResultsPanel />
                            </div>
                        </div>
                    </div>
                    <MetadataPanel cesiumWidget={cesiumWidget} />
                </>,
            ]}
            toggleSearch={false}
        />
    );
};
