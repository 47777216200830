import type { PointPrimitive, Label } from '@cesium/engine';
import { PointPrimitiveCollection, LabelCollection } from '@cesium/engine';
import { useCallback } from 'react';

export function useResetClusters(cesiumWidget: any) {
    const resetClusters = useCallback(() => {
        if (!cesiumWidget) return;
        for (let i = 0; i < cesiumWidget.scene.primitives.length; i += 1) {
            const primitive = cesiumWidget.scene.primitives.get(i);
            if (primitive instanceof PointPrimitiveCollection) {
                for (let j = 0; j < primitive.length; j += 1) {
                    const point = primitive.get(j) as PointPrimitive;
                    point.show = true;
                }
            } else if (primitive instanceof LabelCollection) {
                for (let j = 0; j < primitive.length; j += 1) {
                    const label = primitive.get(j) as Label;
                    label.show = true;
                }
            }
        }
    }, [cesiumWidget]);

    return resetClusters;
}
