import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { BackButton } from '@local/content-area/dist/TitleRow/buttons/BackButton';
import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import { WORKSPACES_LIST_PAGE } from '@local/workspaces/dist/urls';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
    BACK_TO_WORKSPACES,
    WORKSPACE_NOT_FOUND_MESSAGE,
    WORKSPACE_NOT_FOUND_TITLE,
} from 'src/strings';
import { workspaceListingWithLinkBreadcrumb } from 'src/utils/breadcrumbs';

export const NotFound = () => {
    const params = useParams();

    const segments: BreadcrumbSegment[] = workspaceListingWithLinkBreadcrumb(params).map(
        ({ name, path }) => ({
            name,
            path,
        }),
    );
    const { setBreadcrumbs } = useBreadcrumbs();

    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);

    return (
        <>
            <TitleRow
                title=""
                actions={
                    <BackButton
                        text={BACK_TO_WORKSPACES}
                        destination={`/${params.orgUuid}/${WORKSPACES_LIST_PAGE}`}
                    />
                }
            />
            <EmptyState
                sx={{
                    padding: 2,
                    paddingTop: 0,
                    svg: {
                        width: '50%',
                    },
                    marginTop: (theme) => theme.spacing(10),
                }}
                bodySx={{
                    margin: (theme) => theme.spacing(4),
                }}
                image={<NotFoundSvg />}
                title={WORKSPACE_NOT_FOUND_TITLE}
                message={WORKSPACE_NOT_FOUND_MESSAGE}
            />
            ;
        </>
    );
};
