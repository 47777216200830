import type { GetObjectByIdApiArg } from '@api/goose/dist/enhancedGooseClient';
import { getUrlConfig } from '@local/login/dist/util/getUrlConfig';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import { DEFAULT_FLAT_COLOR } from '@local/webviz/dist/context/snapshots/defaults';
import type { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { store } from 'src/store/store';
import {
    generateVisualizationServiceUrl,
    getBlocksyncReferenceAttributes,
} from 'src/visualization/utils/utils';

import type { GeoscienceBlockmodelObject } from '../../../types';
import { generateAttributeListSnapshot } from '../attributeSnapshot';

export function generateBlocksyncReferenceSnapshot(
    viewId: string,
    gooseObject: GeoscienceBlockmodelObject,
    params: GetObjectByIdApiArg,
    dispatch: typeof store.dispatch,
): UpdateSnapshot {
    const { object_id: objectId, object } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);
    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);

    const attributes = getBlocksyncReferenceAttributes(object);
    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        dispatch,
        attributes,
    );

    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Blockmodel, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}
