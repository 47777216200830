import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_OPACITY,
    MESH_DEFAULT_COLOR,
    MESH_DEFAULT_COLOR_BACK,
} from '@local/webviz/dist/context/snapshots/defaults';
import type { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import {
    convertDecimalToRGBA,
    extractTilesetUri,
    findGMMItemInFolders,
} from 'src/visualization/utils/utils';

import type { GeoscienceGMMObject, Tileset } from '../../../types';

export function generateGMMSurfaceSnapshot(
    viewId: string,
    gooseResponse: GeoscienceGMMObject,
    treeItem: TreeStructure,
    tileset: Tileset,
): UpdateSnapshot | undefined {
    const url = extractTilesetUri(tileset, treeItem);

    if (!url) {
        return undefined;
    }

    const elementId = toSuffixUid(treeItem.treeId, ElementClass.Tileset3D);

    const path = treeItem.treeId.split(':').slice(1);
    const { object } = gooseResponse;
    const { folders } = object;

    if (folders.length && folders[0]?.items) {
        const item = findGMMItemInFolders(object.folders[0].items, path);

        let materialColor;
        let opacity;
        if (item?.material_key && object.materials) {
            const materialObject = object.materials.find(
                (material) => material.key === item.material_key,
            );
            if (materialObject?.color) {
                const rgba = convertDecimalToRGBA(materialObject.color);
                if (rgba) {
                    const [r, g, b, a] = rgba;
                    materialColor = [r, g, b];
                    opacity = a;
                }
            }
        }

        return {
            [elementId]: generateEntity(ElementClass.Tileset3D, {
                id: elementId,
                url,
            }),
            [viewId]: generateEntity(ViewClass.Surface, {
                id: viewId,
                element: elementId,
                wireframe: false,
                showFaces: true,
                color: materialColor ?? MESH_DEFAULT_COLOR,
                color_back: materialColor ?? MESH_DEFAULT_COLOR_BACK,
                opacity: opacity ?? DEFAULT_OPACITY,
            }),
        };
    }
    return undefined;
}
