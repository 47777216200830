import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
    SUPPORTED_SCHEMAS,
    DRIVER_SUPPORTED_SCHEMAS,
    BLOCKSYNC_SUPPORTED_SCHEMAS,
} from 'src/utils/supportedTypes';
import { Schemas } from 'src/visualization/constants';

export const extractSchema = (fullSchema: string) => {
    if (!fullSchema) {
        return '' as Schemas;
    }

    return fullSchema.substring(fullSchema.lastIndexOf('/') + 1).toLowerCase() as Schemas;
};

export function isSchemaViewable(fullSchema: string, flags: LDFlagSet) {
    const schema = extractSchema(fullSchema);
    const {
        evouiRegularGridRendering,
        evouiGmmRendering,
        evouiRegularBmRendering,
        evouiStructuralDiskRenderin,
    } = flags;
    if (evouiRegularGridRendering) {
        SUPPORTED_SCHEMAS.push(Schemas.Regular2DGridSchema);
    }
    if (evouiGmmRendering) {
        SUPPORTED_SCHEMAS.push(Schemas.GeologicalModelMeshesSchema);
    }
    if (evouiRegularBmRendering) {
        SUPPORTED_SCHEMAS.push(Schemas.BlockSyncReferenceSchema);
    }
    if (evouiStructuralDiskRenderin) {
        SUPPORTED_SCHEMAS.push(Schemas.PlanarDataPointsetSchema);
    }
    return SUPPORTED_SCHEMAS.includes(schema);
}

export function isSchemaDriverViewable(fullSchema: string) {
    const schema = extractSchema(fullSchema);
    return DRIVER_SUPPORTED_SCHEMAS.includes(schema);
}

export function isSchemaBlockSyncViewable(fullSchema: string) {
    const schema = extractSchema(fullSchema);
    return BLOCKSYNC_SUPPORTED_SCHEMAS.includes(schema);
}
