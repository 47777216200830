import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { AUTO_DISMISS_MS_DEFAULT } from 'src/constants';
import { useSnackBarContext } from 'src/contexts/SnackBarContext';

export function MessageSnackBar() {
    const { message, severity, action, handleClose } = useSnackBarContext();
    return (
        <Snackbar
            open={Boolean(message)}
            disableWindowBlurListener
            autoHideDuration={AUTO_DISMISS_MS_DEFAULT}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} action={action} sx={{ width: '100%' }} severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    );
}
