import { Outlet } from 'react-router-dom';

import { SnackBarProvider } from 'src/contexts/SnackBarContext';
import { WorkspaceProvider } from 'src/contexts/WorkspaceContext';

export function WorkspaceContentContainer() {
    return (
        <SnackBarProvider>
            <WorkspaceProvider>
                <Outlet />
            </WorkspaceProvider>
        </SnackBarProvider>
    );
}

export default WorkspaceContentContainer;
