import { OrgSelectMenuWds2 } from '@local/login';
import {
    getAvailableEvoInstances,
    getCurrentEvoInstance,
    setCurrentEvoInstance,
} from '@local/login/dist/store/sessionStorageHelpers/entitlementsHelper/entitlementsHelper';
import uniqBy from 'lodash-es/uniqBy';
import { useParams } from 'react-router-dom';

export const HubSelector = () => {
    const params = useParams();
    const evoInstances = getAvailableEvoInstances();
    const currentEvoInstance = getCurrentEvoInstance();
    const currentHubCode = params?.hubCode ?? currentEvoInstance?.hub?.code;
    const hubs = uniqBy(
        evoInstances
            .map(({ hub }) => hub)
            .sort((a, b) => (a.display_name.toLowerCase() > b.display_name.toLowerCase() ? 1 : -1)),
        (hub) => hub.display_name,
    );

    function navigateToHub(hubCode: string) {
        // Nothing to do if the user has selected their current hub
        if (hubCode === currentHubCode) {
            return;
        }

        const evoInstance = evoInstances.find((predicate) => predicate.hub.code === hubCode);
        if (evoInstance) {
            setCurrentEvoInstance({ org: evoInstance.org, hub: evoInstance.hub });
            // Not using `navigate()` because we want to force the page to reload even if the URL is the same (different
            // hubs within the same org).
            window.location.replace(`/${evoInstance.org.id}/workspaces`);
        }
    }

    // Do not show the org selector if the user only has access to a single hub
    if (hubs.length === 1) {
        return null;
    }

    return (
        <OrgSelectMenuWds2
            evoInstances={evoInstances}
            sx={{ width: '300px' }}
            onChange={({ hub }) => hub && navigateToHub(hub.code)}
            value={currentEvoInstance}
        />
    );
};
