import throttle from 'lodash-es/throttle';
import { useCallback, useEffect } from 'react';

import { getViewBounds } from '../Points/Clustering/Clustering';
import type { ViewBounds } from '../Points/Clustering/Clustering';

export function useUpdateBounds(
    cesiumWidget: any,
    setViewBounds: (bounds: ViewBounds | null) => void,
    setSearchBounds: (bounds: ViewBounds) => void,
) {
    const throttledUpdateBounds = useCallback(
        throttle(() => {
            const bounds = getViewBounds(cesiumWidget);
            if (bounds) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            }
        }, 200),
        [cesiumWidget, setViewBounds, setSearchBounds],
    );

    useEffect(() => {
        if (!cesiumWidget) return () => {};

        const updateBoundsImmediately = () => {
            const bounds = getViewBounds(cesiumWidget);
            if (bounds) {
                setViewBounds(bounds);
                setSearchBounds(bounds as ViewBounds);
            }
        };

        cesiumWidget.scene.camera.changed.addEventListener(updateBoundsImmediately);

        return () => {
            if (cesiumWidget) {
                cesiumWidget.scene.camera.changed.removeEventListener(updateBoundsImmediately);
            }
        };
    }, [cesiumWidget, setViewBounds, setSearchBounds]);

    return throttledUpdateBounds;
}
