import { ErrorScreen } from '@local/svgs/dist/pageState';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { useGetWorkspaceAffinitySchemaQuery } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { currentViewSelector, setCurrentView } from 'src/store/features/displaySettingSlice';
import { folderFilterSlice } from 'src/store/slices/folderFilterSlice';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    WORKSPACE_CONTENT_NO_CONTENT,
    WORKSPACE_CONTENT_NO_CONTENT_DESC,
} from 'src/strings';

import { ContentOptions } from '../ContentOptions';
import { objectDefinition } from '../workspaceContent/FieldRowDefinitions';
import { TableHeader } from '../workspaceContent/TableHeader';
import { AffinityFolderRow } from './AffinityFolderRow';
import { useStyles } from './AffinityFoldersContent.styles';

const ObjectList = ({ isLoading, objects }: { objects: Folder[]; isLoading: boolean }) => {
    if (isLoading) {
        return (
            <PaginatedList
                header={<TableHeader fields={objectDefinition} hasActions />}
                content={<TableSkeleton rows={3} columns={4} hasAction />}
            />
        );
    }

    if (!isLoading && !objects.length) {
        return (
            <EmptyState
                title={WORKSPACE_CONTENT_NO_CONTENT}
                message={WORKSPACE_CONTENT_NO_CONTENT_DESC}
                image={<NotFoundSvg />}
            />
        );
    }

    return (
        <PaginatedList
            header={<TableHeader fields={objectDefinition} hasActions />}
            stickyHeader
            isEmpty={!objects.length}
            content={objects.map((folder: Folder) => (
                <AffinityFolderRow key={folder.name} folder={folder} />
            ))}
            containerSx={{ height: 'calc(100vh - 360px)', overflowY: 'hidden' }}
        />
    );
};
const {
    actions: { setFolderFilter },
} = folderFilterSlice;
export const AffinityFoldersContent = () => {
    const { classes } = useStyles();
    const params = useParams();
    const currentView = useAppSelector(currentViewSelector);
    const dispatch = useAppDispatch();
    const {
        data: gooseData,
        isLoading,
        isError,
    } = useGetWorkspaceAffinitySchemaQuery({
        hubUrl: 'https://workspace.dev-sqnt.com/workspace',
        orgId: getOrgUuidFromParams(params),
        workspaceId: 'talking-to-mocks-for-now',
    });

    useEffect(() => {
        if (gooseData && !currentView) {
            dispatch(setCurrentView(gooseData?.schemas[0].name));
        }
        if (currentView !== '') {
            // fixes edge case where loading has just finished and the state update will show on next render
            // without this the empty state flashes
            dispatch(
                setFolderFilter({
                    foldersFilter: gooseData?.schemas.find((schema) => schema.name === currentView)
                        ?.folders,
                }),
            );
        }
    }, [gooseData, currentView]);

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }

    return (
        <>
            <ContentOptions />
            <div className={classes.table}>
                <Grid container direction="column" item>
                    <ObjectList
                        objects={
                            gooseData?.schemas.find((schema) => schema.name === currentView)
                                ?.folders ?? []
                        }
                        isLoading={isLoading}
                    />
                </Grid>
            </div>
        </>
    );
};
