import type { Cartesian3, CesiumWidget, Scene } from '@cesium/engine';
import { useEffect, useState } from 'react';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import { useSearchInteractionContext } from 'src/contexts/SearchInteractionContext';

import { useBoundingBox } from '../../Hooks/useBoundingBox';
import { ObjectIcon } from '../ObjectIcon';

export const EvoPoint = ({
    billboard,
    position,
    scene,
    cesiumWidget,
    flyToInProgress,
}: {
    billboard: any;
    position: { x: number; y: number };
    scene: Scene;
    cesiumWidget: CesiumWidget;
    flyToInProgress: React.MutableRefObject<boolean>;
}) => {
    const { objects, setActiveObject, activeObject } = useDiscoverContext();
    const { hoveredObject } = useSearchInteractionContext();
    const [canvasPosition, setCanvasPosition] = useState<{ x: number; y: number } | null>(null);
    const boundingBox = useBoundingBox(cesiumWidget, flyToInProgress);

    const object = objects.find((obj) => obj.object_id === billboard.id);

    useEffect(() => {
        let animationFrameId: number;

        const updatePosition = () => {
            const pos = scene.cartesianToCanvasCoordinates(position as Cartesian3);
            setCanvasPosition({ x: pos.x, y: pos.y });
            animationFrameId = requestAnimationFrame(updatePosition);
        };

        updatePosition();

        return () => cancelAnimationFrame(animationFrameId);
    }, [scene, position]);

    const handleOnClick = () => {
        if (!object) return;
        setActiveObject(object!.object_id);
        boundingBox(object);
    };

    return (
        <div
            style={{
                position: 'absolute',
                top: canvasPosition?.y,
                left: canvasPosition?.x,
                zIndex: 1000,
                transform: 'translate(-50%, -50%)',
            }}
        >
            <ObjectIcon
                key={object!.object_id}
                schema={object!.schema}
                objectID={object!.object_id}
                hovered={object!.object_id === hoveredObject}
                onClick={handleOnClick}
                active={object!.object_id === activeObject?.object_id}
                name={object!.name.substring(0, object!.name.length - 5)}
            />
        </div>
    );
};
