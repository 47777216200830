import type { GetObjectByIdApiArg } from '@api/goose/dist/enhancedGooseClient';
import { getUrlConfig } from '@local/login/dist/util/getUrlConfig';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    MESH_DEFAULT_COLOR,
    MESH_DEFAULT_COLOR_BACK,
} from '@local/webviz/dist/context/snapshots/defaults';
import type { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { store } from 'src/store/store';
import {
    generateVisualizationServiceUrl,
    getTriangleMeshAttributes,
} from 'src/visualization/utils/utils';

import type { GeoscienceMeshObject } from '../../../types';
import { generateAttributeListSnapshot } from '../attributeSnapshot';

export function generateSurfaceSnapshot(
    viewId: string,
    gooseObject: GeoscienceMeshObject,
    params: GetObjectByIdApiArg,
    dispatch: typeof store.dispatch,
): UpdateSnapshot {
    const { object_id: objectId, object } = gooseObject;

    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);

    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);
    const attributes = getTriangleMeshAttributes(object);

    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        dispatch,
        attributes,
    );
    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Surface, {
            id: viewId,
            element: elementId,
            wireframe: false,
            showFaces: true,
            color: MESH_DEFAULT_COLOR,
            color_back: MESH_DEFAULT_COLOR_BACK,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}
