import type { AlertColor } from '@mui/material/Alert';
import React, { createContext, useContext, useMemo, useState } from 'react';

interface Context {
    message: string;
    severity: AlertColor;
    action: React.ReactNode | null;
    setSnackBar: (
        snackBarMessage: string,
        snackBarSeverity: AlertColor,
        snackBarAction: React.ReactNode | null,
    ) => void;
    handleClose: () => void;
}

// Context used to store information for a specific workspace.
// When the workspace changes, the context values should be reset.
export const SnackBarContext = createContext<Context>({
    message: '',
    severity: 'success',
    action: null,
    setSnackBar: () => {},
    handleClose: () => {},
});

export const useSnackBarContext = () => useContext(SnackBarContext);

interface Props {
    children: React.ReactNode;
}

export const SnackBarProvider = ({ children }: Props) => {
    const [message, setMessage] = useState('');
    const [action, setAction] = useState<React.ReactNode>(null);
    const [severity, setSeverity] = useState<AlertColor>('success');

    const handleClose = () => {
        setMessage('');
        setAction(null);
        setSeverity('success');
    };
    const setSnackBar = (
        snackBarMessage: string,
        snackBarSeverity: AlertColor,
        snackBarAction: React.ReactNode | null,
    ) => {
        setMessage(snackBarMessage);
        setSeverity(snackBarSeverity);
        setAction(snackBarAction);
    };

    return (
        <SnackBarContext.Provider
            value={useMemo(
                () => ({
                    message,
                    action,
                    severity,
                    setSnackBar,
                    handleClose,
                }),
                [message, action, severity, setSnackBar, handleClose],
            )}
        >
            {children}
        </SnackBarContext.Provider>
    );
};
