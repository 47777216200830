import type { GetObjectByIdApiArg } from '@api/goose/dist/enhancedGooseClient';
import { getUrlConfig } from '@local/login/dist/util/getUrlConfig';
import { generateEntity } from '@local/webviz/dist/context/snapshots/base';
import {
    DEFAULT_FLAT_COLOR,
    POINTS_DEFAULT_MODE,
    POINTS_DEFAULT_SIZE,
} from '@local/webviz/dist/context/snapshots/defaults';
import type { UpdateSnapshot } from '@local/webviz/dist/types/xyz';
import { ElementClass, ViewClass, toSuffixUid } from '@local/webviz/dist/xyz';

import type { store } from 'src/store/store';
import { generateVisualizationServiceUrl } from 'src/visualization/utils/utils';

import type { GeosciencePointObject } from '../../../types';
import { generateAttributeListSnapshot } from '../attributeSnapshot';

export function generateTiledPointsSnapshot(
    viewId: string,
    gooseObject: GeosciencePointObject,
    params: GetObjectByIdApiArg,
    dispatch: typeof store.dispatch,
): UpdateSnapshot {
    const { object_id: objectId, object } = gooseObject;
    const { locations } = object;
    const { attributes } = locations;
    const elementId = toSuffixUid(objectId, ElementClass.Tileset3D);
    const { url: baseUrl } = getUrlConfig();
    const url = generateVisualizationServiceUrl(baseUrl, params);
    const [firstColorData, attributesSnapshot] = generateAttributeListSnapshot(
        dispatch,
        attributes,
    );
    return {
        [elementId]: generateEntity(ElementClass.Tileset3D, {
            url,
        }),
        [viewId]: generateEntity(ViewClass.Points, {
            id: viewId,
            element: elementId,
            color: DEFAULT_FLAT_COLOR,
            size: POINTS_DEFAULT_SIZE,
            mode: POINTS_DEFAULT_MODE,
            color_data: firstColorData,
        }),
        ...attributesSnapshot,
    };
}
