export enum Schemas {
    DesignGeometrySchema = 'design-geometry.schema.json',
    DownholeCollectionSchema = 'downhole-collection.schema.json',
    DownholeIntervalsSchema = 'downhole-intervals.schema.json',
    ExploratoryLocationSchema = 'exploratory-location.schema.json',
    GeologicalModelMeshesSchema = 'geological-model-meshes.schema.json',
    GeophysicalRecords1DSchema = 'geophysical-records-1d.schema.json',
    GlobalEllipsoidSchema = 'global-ellipsoid.schema.json',
    IntervalDownholesSchema = 'interval-downholes.schema.json',
    LineSegmentsSchema = 'line-segments.schema.json',
    LineationsDataPointsetSchema = 'lineations-data-pointset.schema.json',
    LocalEllipsoidsSchema = 'local-ellipsoids.schema.json',
    NonParametricContinuousCumulativeDistributionSchema = 'non-parametric-continuous-cumulative-distribution.schema.json',
    PlanarDataPointsetSchema = 'planar-data-pointset.schema.json',
    PointsetSchema = 'pointset.schema.json',
    Regular2DGridSchema = 'regular-2d-grid.schema.json',
    Regular3DGridSchema = 'regular-3d-grid.schema.json',
    RegularMasked3DGridSchema = 'regular-masked-3d-grid.schema.json',
    Tensor2DGridSchema = 'tensor-2d-grid.schema.json',
    Tensor3DGridSchema = 'tensor-3d-grid.schema.json',
    TriangleMeshSchema = 'triangle-mesh.schema.json',
    UnstructuredGridSchema = 'unstructured-grid.schema.json',
    UnstructuredHexGridSchema = 'unstructured-hex-grid.schema.json',
    UnstructuredQuadGridSchema = 'unstructured-quad-grid.schema.json',
    UnstructuredTetGridSchema = 'unstructured-tet-grid.schema.json',
    VariogramSchema = 'variogram.schema.json',
    BlockSyncReferenceSchema = 'blocksync-reference.schema.json',
}

export enum AttributeTypes {
    Category = 'category',
    String = 'string',
    Bool = 'bool',
    BoolTimeSeries = 'bool_time_series',
    CategoricalTimeSeries = 'categorical_time_series',
    ContinuousTimeSeries = 'continuous_time_series',
    DateTime = 'date_time',
    EnsembleCategory = 'ensemble_category',
    EnsembleContinuous = 'ensemble_continuous',
    Scalar = 'scalar',
}

export enum BlocksyncAttributeTypesMap {
    Utf8 = AttributeTypes.Category,
    Float64 = AttributeTypes.Scalar,
    Boolean = AttributeTypes.Bool,
    Int8 = AttributeTypes.Scalar,
    Int16 = AttributeTypes.Scalar,
    Int32 = AttributeTypes.Scalar,
    Int64 = AttributeTypes.Scalar,
    UInt8 = AttributeTypes.Scalar,
    UInt16 = AttributeTypes.Scalar,
    UInt32 = AttributeTypes.Scalar,
    UInt64 = AttributeTypes.Scalar,
    Float16 = AttributeTypes.Scalar,
    Float32 = AttributeTypes.Scalar,
    Date32 = AttributeTypes.DateTime,
    Timestamp = AttributeTypes.DateTime,
}

export const LOAD_SIZE = 20;
