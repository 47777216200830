import {
    useLazyGetAssociationCollectionQuery,
    useLazyGetColormapCollectionQuery,
} from '@api/colormap/dist/enhancedColormapClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import type { AttributeColormapAssociationMap } from '@local/webviz/dist/components/Properties/PropertiesDialog';
import { useBaseXyz } from '@local/webviz/dist/context';
import { createSnapshotToUpdateColormapAssociations } from '@local/webviz/dist/context/snapshots/colorMapping';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities';
import { toSuffixUid } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useParams } from 'react-router-dom';

import { store, useAppDispatch } from 'src/store/store';
import { attributeKeyMap } from 'src/store/visualization/selectors';
import { updateAttributeColormapAssociations } from 'src/store/visualization/visualizationSlice';

export function useAttributeColormapAssociation() {
    const { evouiEnableColormapsApi } = useFlags();
    const { getEntityState, setStateFromSnapshot } = useBaseXyz();
    const dispatch = useAppDispatch();

    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const [fetchColormaps] = useLazyGetColormapCollectionQuery();
    const [fetchAssociationCollection] = useLazyGetAssociationCollectionQuery();

    async function loadColormapsByWorkspaceId() {
        try {
            const colormapList = await fetchColormaps({
                orgId,
                workspaceId,
                'api-preview': 'opt-in',
            }).unwrap();
            return colormapList;
        } catch (error) {
            trackError(
                `Error loading colormaps for the workspace: ${workspaceId}`,
                JSON.stringify(error),
            );
            return '';
        }
    }

    async function loadAssociationCollection(objectId: string) {
        try {
            const associationList = await fetchAssociationCollection({
                orgId,
                workspaceId,
                objectId,
                'api-preview': 'opt-in',
            }).unwrap();
            return associationList;
        } catch (error) {
            trackError(
                `Error loading association collection for objectId: ${objectId}`,
                JSON.stringify(error),
            );
            return '';
        }
    }

    async function handleAttributeColormapAssociations(objectId: string) {
        if (!evouiEnableColormapsApi || !objectId) {
            return;
        }

        const colormapsList = await loadColormapsByWorkspaceId();
        if (!colormapsList) {
            return;
        }

        const associationsList = await loadAssociationCollection(objectId);
        if (!associationsList) {
            return;
        }

        const { colormaps } = colormapsList;
        const { associations } = associationsList;

        if (!colormaps.length || !associations.length) {
            return;
        }

        const attributeColormapAssociationMap = associations.reduce(
            (
                acc: AttributeColormapAssociationMap,
                { colormap_id: colormapId, attribute_id: attributeId },
            ) => {
                // this handles mapping between attribute key and attribute_<hash> id from xyz state
                const attributeKeyMapping = attributeKeyMap(store.getState());
                const attributeKey = attributeKeyMapping[attributeId];
                if (!attributeKey) {
                    return acc;
                }
                const dataId = toSuffixUid(attributeKey, UID_SUFFIXES.DATA);
                const colormap = colormaps.find(({ id }) => id === colormapId);
                if (colormap) {
                    acc[dataId] = acc[dataId] ?? [];
                    const duplicateColormap = acc[dataId].find(({ id }) => id === colormap.id);
                    if (!duplicateColormap) {
                        acc[dataId].push(colormap);
                    }
                }
                return acc;
            },
            {},
        );

        dispatch(
            updateAttributeColormapAssociations({
                objectId,
                attributeAssociationMap: attributeColormapAssociationMap,
            }),
        );

        const snapshot = createSnapshotToUpdateColormapAssociations(
            attributeColormapAssociationMap,
            getEntityState,
        );
        setStateFromSnapshot(snapshot);
    }

    return handleAttributeColormapAssociations;
}
