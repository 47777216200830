// Upload file polling constants
export const RETRIES = 120;
export const WAIT_TIME_MS = 1000;

export const FLY_TO_BOUNDS_DURATION = 1;
export const FLY_TO_BOUNDS_PADDING = 200;

export const ENV_DEVELOPMENT = 'development';

/** Default time in milliseconds before the message will be automatically dismissed */
export const AUTO_DISMISS_MS_DEFAULT = 5000;
