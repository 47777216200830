import { trackUserAction } from '@local/metrics';
import { Search } from '@local/web-design-system-2';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import debounce from 'lodash-es/debounce';
import { useRef } from 'react';

interface SearchFieldProps extends React.ComponentProps<typeof TextField> {
    userAction: string;
    debounceDelay?: number;
    userActionCategory: string;
    onSearchCallBack: (value: string) => void;
}

export function SearchField({
    onSearchCallBack,
    userAction,
    userActionCategory,
    debounceDelay = 500,
    InputProps = {},
    ...otherProps
}: SearchFieldProps) {
    const inputRef = useRef<HTMLInputElement>(null);

    function trackedSearch(term: string) {
        onSearchCallBack(term);
        // Don't actually track what they type as its PII
        trackUserAction(userAction, {
            label: 'User typed something in the search box',
            category: userActionCategory,
        });
    }

    const onSearchCallBackDebounced = debounce(trackedSearch, debounceDelay, {
        maxWait: 1500,
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onSearchCallBackDebounced(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const text = inputRef.current?.value;
        if (event.key === 'Enter' && text) {
            onSearchCallBackDebounced(text);
        }
    };

    return (
        <TextField
            {...otherProps}
            inputRef={inputRef}
            onChange={handleChange}
            onKeyUp={handleKeyPress}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" disablePointerEvents>
                        <Search fontSize="small" color="secondary" />
                    </InputAdornment>
                ),
                ...InputProps,
            }}
            sx={{
                '& input::placeholder': {
                    opacity: 1,
                },
            }}
        />
    );
}
