import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { MapControlTooltip } from '@local/map-viewer/dist/controls/MapControlsTooltip';
import { MapLayerControlOld } from '@local/map-viewer/dist/controls/MapLayerControlOld';
import { ResetZoomButtonOld } from '@local/map-viewer/dist/controls/ResetZoomButtonOld';
import { ZoomInButtonOld } from '@local/map-viewer/dist/controls/ZoomInButtonOld';
import { ZoomOutButtonOld } from '@local/map-viewer/dist/controls/ZoomOutButtonOld';
import { LeafletControlContainer } from '@local/map-viewer/dist/layers/LeafletControlContainer';
import { MapEventBoundary } from '@local/map-viewer/dist/layers/MapEventBoundary';
import { MapViewer } from '@local/map-viewer/dist/MapViewer';
import type { BoundingBoxCoords } from '@local/map-viewer/dist/types';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { AttributionControl } from 'react-leaflet';

import { useDiscoverContext } from 'src/contexts/DiscoverContext';
import { SearchInteractionProvider } from 'src/contexts/SearchInteractionContext';
import { searchBreadcrumb } from 'src/utils/breadcrumbs';

import { GLOBAL_MAP, SEARCH_TITLE } from '../../strings';
import { ClusteredObjectMarkers } from './map/clustering';
import { ClearBoundingBoxButton } from './map/controls/ClearBoundingBoxButton';
import { DrawBoundingBoxButton } from './map/controls/DrawBoundingBoxButton';
import { DisplayCoordinates } from './map/displayCoordinates/DisplayCoordinates';
import { ScaleBar } from './map/scaleBar/ScaleBar';
import { MetadataPanel } from './metadataPanel/MetadataPanel';
import { ResultsPanel } from './resultsPanel/ResultsPanel';
import { useStyles } from './SearchPage.styles';

export function SearchPage() {
    const { classes } = useStyles();
    const [bounds, setBounds] = useState<BoundingBoxCoords | null>(null);
    const [boundingBox, setBoundingBox] = useState<BoundingBoxCoords | null>(null);
    const { setSearchBounds, setSearchQuery } = useDiscoverContext();
    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [{ name: searchBreadcrumb.name }];
    useEffect(() => {
        setBreadcrumbs(segments);
    }, []);

    // We provide either a specified bounding box, or the map's viewport (bounds) as a query filter
    useEffect(() => {
        setSearchBounds(boundingBox ?? bounds);
    }, [boundingBox, bounds]);

    setDocumentTitle('Search');

    return (
        <SearchInteractionProvider>
            <Grid container>
                <Typography
                    variant="h3"
                    sx={(theme) => ({ paddingBottom: theme.spacing(2) })}
                    automation-id="title-row-title-text"
                >
                    {SEARCH_TITLE}
                </Typography>
                <MapViewer
                    addMapLayers={false}
                    addZoomControls={false}
                    setQuery={setSearchQuery}
                    onBoundsChange={setBounds}
                    attributionControl={false}
                    displayCoordinates={false}
                >
                    <LeafletControlContainer position="topleft" interactive={false}>
                        <Grid
                            gap={2}
                            container
                            wrap="nowrap"
                            direction="row"
                            className={classes.searchContainer}
                        >
                            <MapEventBoundary stopClickPropagation stopScrollPropagation>
                                <ResultsPanel />
                            </MapEventBoundary>
                            <MapEventBoundary stopClickPropagation stopScrollPropagation>
                                <DrawBoundingBoxButton onChange={setBoundingBox} />
                            </MapEventBoundary>
                            <MapEventBoundary stopClickPropagation stopScrollPropagation>
                                <ClearBoundingBoxButton onClear={() => setBoundingBox(null)} />
                            </MapEventBoundary>
                        </Grid>
                    </LeafletControlContainer>
                    <LeafletControlContainer position="topright" interactive>
                        <Grid
                            container
                            gap={2}
                            direction="column"
                            className={classes.controlContainer}
                        >
                            <ZoomInButtonOld variant="dark" />
                            <ZoomOutButtonOld variant="dark" />
                            <MapControlTooltip title={GLOBAL_MAP} placement="left">
                                <span>
                                    <ResetZoomButtonOld variant="dark" />
                                </span>
                            </MapControlTooltip>
                            <MapLayerControlOld variant="dark" displayTooltip />
                        </Grid>
                    </LeafletControlContainer>
                    <LeafletControlContainer position="bottomright" interactive={false}>
                        <Grid
                            container
                            gap={2}
                            direction="column"
                            className={classes.metadataContainer}
                        >
                            <MetadataPanel />
                            <ScaleBar barWidth={140} />
                        </Grid>
                    </LeafletControlContainer>
                    <LeafletControlContainer position="bottom" interactive={false} fullWidth>
                        <Grid container justifyContent="center" className={classes.bottomContainer}>
                            <DisplayCoordinates />
                        </Grid>
                    </LeafletControlContainer>
                    <ClusteredObjectMarkers />
                    <AttributionControl position="bottomleft" prefix="Leaflet" />
                </MapViewer>
            </Grid>
        </SearchInteractionProvider>
    );
}
