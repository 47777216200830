export enum UserAction {
    APP_LOGIN_CLICKED = 'App login button clicked',
    APP_REQUEST_TRIAL_CLICKED = 'App request a trial button clicked',
    APP_REGISTER_INTEREST_CLICKED = 'App register interest button clicked',
    APP_LEARN_MORE_CLICKED = 'App learn more button clicked',
    GOOSE_SEARCH = 'Searched Goose objects',
    USER_REMOVED = 'Removed a user',
    USER_INVITED = 'Invited a user',
    USER_ROLE_UPDATED = 'Updated a user role or entitlement',
    ADMIN_CREATED_ADMIN = 'Admin user created/invited another admin',
    GOOSE_OBJECT_CLICKED = 'User opened Geoscience object properties',
    USER_TOGGLED_AFFINITIES_VIEW = 'User toggled affinities schema view',
    USER_CLICKED_GO_DROPDOWN_FILTER = 'User clicked the GO dropdown filter',
    USER_APPLIED_GO_DROPDOWN_FILTER = 'User applied the GO dropdown filter',

    USER_CLICKED_VIEWER_BUTTON = 'User clicked the Webviz Viewer button',
    USER_CLICKED_SLICE_BUTTON = 'User clicked the Slice button',
    USER_CLICKED_MEASURE_BUTTON = 'User clicked the Measure button',

    OBJECT_FAILED_TO_RENDER = 'Object failed to render',
    OBJECT_LOAD_TIME = 'Object load time',

    USER_CREATED_COLORMAP = 'User created a colormap',
}

export enum Categories {
    EVO_LANDING_PAGE = 'Evo Landing Page',
    WORKSPACE_CONTENT_PAGE = 'Workspace content page',
    EVO_USERS_PAGE = 'Evo Users Page',
    EVO_USER_MANAGEMENT = 'Evo user management',
    AFFINITY_SCHEMA = 'Affinity Schema',
    GO_DROPDOWN_FILTER = 'GO dropdown filter',
}
