import { gooseClient } from '@api/goose/dist/enhancedGooseClient';
import {
    LoadTimeTracker,
    LoadTrackerActions,
} from '@local/metrics/dist/src/components/LoadTimeTracker';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import { ComputeStatus } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { UserAction } from 'src/metrics.types';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
    getObjectTree,
    getTreeItemById,
    loadedObjectById,
    loadedObjectsMap,
    selectionListProjectTree,
} from 'src/store/visualization/selectors';
import {
    addToLoadedObjects,
    removeFromLoadedObjects,
} from 'src/store/visualization/visualizationSlice';
import { ROOT_TREE_ID } from 'src/strings';
import { getViewIdFromObjectId } from 'src/visualization/context/snapshots/generateSnapshot';
import {
    OBJECT_FAILED_TO_RENDER,
    OBJECT_TOO_LARGE,
} from 'src/visualization/ObjectsPanel/ProjectTree/ObjectListItemControl/ObjectListItemControl.constants';

/**
 * You only need to supply the listItemId if you want to listen for the object to be loaded, in most cases you will want this
 * @param listItemId
 * @returns
 */
export function useObjectManager(listItemId: string) {
    const dispatch = useAppDispatch();
    const selectedObjectIds = useAppSelector(selectionListProjectTree);
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    const treeItem = useAppSelector(getTreeItemById(listItemId));

    function loadObject() {
        if (!treeItem?.schema) return;
        if (isObjectLoaded(listItemId)) return;
        setErrorMessage('');
        dispatch(addToLoadedObjects(treeItem));
    }
    function reloadObject() {
        if (!treeItem?.schema) return;
        setErrorMessage('');
        // First, we remove the object from the loaded objects so that it can be reloaded
        dispatch(removeFromLoadedObjects(listItemId));

        // Then, we dispose the entity to remove it from the plot
        const viewId = getViewIdFromObjectId(listItemId, treeItem.schema);
        disposeEntity(viewId);

        // Finally, we add the object back to the loaded objects
        // It needs to be inside a setTimeout because Redux won't unmount the ListItem
        // component if we dispatch this action synchronously
        setTimeout(() => dispatch(addToLoadedObjects(treeItem)));
    }

    const { treeState } = useAppSelector(getObjectTree);
    function loadSelectedObjects() {
        selectedObjectIds.forEach((treeId) => {
            const selectedTreeItem = treeState[treeId];
            if (selectedTreeItem) {
                dispatch(addToLoadedObjects(selectedTreeItem));
            }
        });
    }

    const loadedObjects = useAppSelector(loadedObjectsMap);
    function isObjectLoaded(objectId: string) {
        return objectId in loadedObjects;
    }

    const { useXyzListener, addViewStatusListener, disposeEntity } = useBaseXyz();
    const [views, setViews] = useState<string[]>([]);
    useXyzListener('plot', 'views', (plotViews: string[]) => {
        setViews(plotViews);
    });

    const queryObjectId =
        treeItem && treeItem.parentId !== ROOT_TREE_ID ? treeItem.parentId : listItemId;

    const selectQuery = useMemo(
        () =>
            gooseClient.endpoints.getObjectById.select({
                orgId,
                workspaceId,
                objectId: queryObjectId,
            }),
        [],
    );

    const { isError: isNetworkError } = useAppSelector(selectQuery);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const loadedObject = useAppSelector(loadedObjectById(listItemId));
    const loadedObjectExists = Boolean(loadedObject);

    useEffect(() => {
        if (isObjectLoaded(listItemId)) {
            setIsLoading(true);
        }
    }, [loadedObjectExists]);

    useEffect(() => {
        if (isNetworkError) {
            setErrorMessage(OBJECT_FAILED_TO_RENDER);
        }
    }, [isNetworkError]);

    let objectTracked = false;
    let objectPending = false;

    useEffect(() => {
        const tracker = new LoadTimeTracker();
        const objectViewId = views.find((view) => view.startsWith(listItemId));
        if (!objectViewId) {
            setErrorMessage('');
            if (!loadedObject) {
                setIsLoading(false);
            }
            return () => {};
        }

        return addViewStatusListener({
            viewId: objectViewId,
            onComplete: () => {
                if (tracker.initialTime && !objectTracked) {
                    tracker.trackCompletedEvent({
                        action: UserAction.OBJECT_LOAD_TIME,
                        label: `${LoadTrackerActions.LOAD_COMPLETED} - ${
                            treeItem?.schema.split('.')[0]
                        }`,
                    });
                    objectTracked = true;
                }

                setIsLoading(false);
                setErrorMessage('');
            },
            onError: (failedKey: string) => {
                trackError(`Error retrieving status on ${failedKey}`);
                setIsLoading(false);
                let errorMsg = '';
                if (failedKey === ComputeStatus.FailedTooBig) {
                    errorMsg = OBJECT_TOO_LARGE;
                } else {
                    errorMsg = OBJECT_FAILED_TO_RENDER;
                }
                setErrorMessage(errorMsg);
                tracker.trackCompletedEvent({
                    action: UserAction.OBJECT_FAILED_TO_RENDER,
                    label: errorMsg,
                });
            },
            onPending: () => {
                if (!objectPending) {
                    tracker.trackLoadEvent();
                }
                setIsLoading(true);
                setErrorMessage('');
                objectPending = true;
            },
        });
    }, [views]);

    return {
        loadObject,
        loadSelectedObjects,
        reloadObject,
        errorMessage,
        isLoading,
        isObjectLoaded,
    };
}
