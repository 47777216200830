import type { OrgListedObject } from '@api/goose/dist/enhancedGooseClient';
import { useListObjectsByOrgQuery } from '@api/goose/dist/enhancedGooseClient';
import type { BreadcrumbSegment } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { useBreadcrumbs } from '@local/content-area/dist/Breadcrumbs/BreadcrumbsContext';
import { ErrorScreen } from '@local/svgs/dist/pageState';
import { RecycleBinEmptySvg } from '@local/svgs/dist/svg/RecycleBinEmptySvg';
import EmptyState from '@local/web-design-system-2/dist/components/EmptyState/EmptyState';
import type { FieldDefinition } from '@local/web-design-system-2/dist/components/GenericListing/types';
import { Order } from '@local/web-design-system-2/dist/components/GenericListing/types';
import type { HeadCell } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import { SortedList } from '@local/web-design-system-2/dist/components/SortedList/SortedList';
import TableSkeleton from '@local/web-design-system-2/dist/components/TableSkeleton/TableSkeleton';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import {
    CategoriesWorkspace,
    UserActionWorkspace,
} from '@local/workspaces/dist/WorkspaceMetrics.types';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/material/styles/useTheme';
import TablePagination from '@mui/material/TablePagination';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PageContent } from 'src/components/pageContent/PageContent';
import { PaginatedList } from 'src/components/paginatedList/PaginatedList';
import { SearchField } from 'src/components/searchField/SearchField';
import { useObjectSearchParams } from 'src/hooks/useObjectSearchParams';
import { useObjectSortParams } from 'src/hooks/useObjectSortParams';
import { usePagination } from 'src/hooks/usePagination';
import { TableHeader } from 'src/pages/workspacePage/workspaceContent/TableHeader';
import {
    ENTER_OBJECT_NAME,
    NETWORK_ERROR_DESCR,
    NETWORK_ERROR_TITLE,
    RECYCLE_BIN_NO_CONTENT,
    RECYCLE_BIN_NO_CONTENT_DESC,
    RECYCLE_BIN_TITLE,
    SEARCH_LABEL,
} from 'src/strings';
import { recycleBinBreadcrumb } from 'src/utils/breadcrumbs';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from 'src/utils/pagination';

import {
    objectDefinition,
    recycledObjectDefinition,
} from '../workspacePage/workspaceContent/FieldRowDefinitions';
import { useStyles } from './RecycleBinPage.styles';
import { RecycledObjectRow } from './RecycledObjectRow';

interface OrgObjectListProps {
    objects: OrgListedObject[];
    isLoading: boolean;
    handleSort: (values: { key: string; order: Order }) => void;
    fields?: FieldDefinition[];
}
export interface ListedObjectDisplay extends OrgListedObject {
    depth: number;
}
export const isListedObjectDisplay = (object: ListedObjectDisplay) => 'created_at' in object;
export const processRecycleObjectContents = (depth: number, contents?: OrgListedObject[]) => {
    if (!contents) {
        return [];
    }

    return contents.map(
        (object: OrgListedObject) =>
            ({
                ...object,
                depth,
            }) as ListedObjectDisplay,
    );
};

export const OrgObjectList = (props: OrgObjectListProps) => {
    const theme = useTheme();
    const { objects, isLoading, handleSort, fields = objectDefinition } = props;
    const { classes } = useStyles();

    const headCells: HeadCell<ListedObjectDisplay>[] = [
        ...fields.map((field) => ({
            id: field.key as keyof ListedObjectDisplay,
            label: field.label,
            sortable: !!field.sortFunction,
        })),
        {
            id: 'extraHeader' as keyof ListedObjectDisplay,
            label: '',
            sortable: false,
        },
    ];
    const renderRow = (object: ListedObjectDisplay) => (
        <RecycledObjectRow object={object} key={object.object_id} />
    );

    if (isLoading) {
        return (
            <PaginatedList
                header={<TableHeader fields={fields} />}
                content={<TableSkeleton rows={4} columns={fields.length} />}
            />
        );
    }
    if (!isLoading && !objects.length) {
        return (
            <Grid container flexGrow={1} alignItems="center" justifyContent="center">
                <EmptyState
                    title={RECYCLE_BIN_NO_CONTENT}
                    titleSx={{ py: 1 }}
                    message={RECYCLE_BIN_NO_CONTENT_DESC}
                    messageSx={{ color: theme.palette.grey[700], py: 1 }}
                    image={
                        <div className={classes.noObjectsImage}>
                            <RecycleBinEmptySvg />
                        </div>
                    }
                    imageSx={{ py: 0 }}
                />
            </Grid>
        );
    }

    const processedObjects = processRecycleObjectContents(0, objects);

    return (
        <SortedList
            data={processedObjects}
            headCells={headCells}
            renderRow={renderRow}
            defaultRowsPerPage={DEFAULT_PAGE_SIZE}
            isApiSort
            onSortChange={(key, newOrder) => {
                handleSort({
                    key: key as string,
                    order: newOrder === 'asc' ? Order.ASCENDING : Order.DESCENDING,
                });
            }}
            defaultOrderBy={
                fields.find((f) => f.defaultSortSettings)?.key as keyof ListedObjectDisplay
            }
            defaultOrder={
                fields.find((f) => f.defaultSortSettings)?.defaultSortSettings?.order ===
                Order.ASCENDING
                    ? 'asc'
                    : 'desc'
            }
            containerSx={{ height: 'calc(100vh - 320px)' }}
        />
    );
};
export function RecycleBinPage() {
    const params = useParams();
    const { classes } = useStyles();
    const { page, pageSize, handleSetPage, handlePageSizeChange, setPaginationParams } =
        usePagination();
    setDocumentTitle(RECYCLE_BIN_TITLE);
    const { updateObjectSortParams, order, orderBy, key } = useObjectSortParams();
    const { objectName, search, setSearch } = useObjectSearchParams();
    const { setBreadcrumbs } = useBreadcrumbs();
    const segments: BreadcrumbSegment[] = [{ name: recycleBinBreadcrumb.name }];
    useEffect(() => setBreadcrumbs(segments), []);
    const fields = recycledObjectDefinition.map((field) =>
        field.key === key ? { ...field, defaultSortSettings: { order: order as Order } } : field,
    );
    const handleSort = (values: { key: string; order: Order }) => {
        if (order === values.order && key === values.key) {
            return;
        }
        updateObjectSortParams(values);
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    };
    useEffect(() => {
        setPaginationParams((currentParams) => ({ ...currentParams, page: 0 }));
    }, [objectName]);
    const {
        data: gooseData,
        isFetching,
        isError,
    } = useListObjectsByOrgQuery({
        orgId: getOrgUuidFromParams(params),
        deleted: true,
        objectName,
        orderBy,
        offset: page * pageSize,
        limit: pageSize,
        permittedWorkspacesOnly: true,
    });

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        handleSetPage(newPage);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        if (!Number.isNaN(newPageSize)) {
            handlePageSizeChange(newPageSize);
        }
    };

    if (isError) {
        return <ErrorScreen msg={NETWORK_ERROR_TITLE} details={NETWORK_ERROR_DESCR} />;
    }
    return (
        <Grid container wrap="nowrap" direction="column">
            <PageContent pageTitle={RECYCLE_BIN_TITLE} enablePageTitle={false}>
                <Stack sx={{ width: '100%' }}>
                    <Stack
                        direction="row"
                        sx={{ paddingLeft: '16px' }}
                        justifyContent="space-between"
                        automation-id="pagination-menu"
                    >
                        <TablePagination
                            page={page}
                            component="div"
                            rowsPerPage={pageSize}
                            labelRowsPerPage="Show"
                            count={gooseData?.total ?? 0}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                            classes={{ toolbar: classes.pagination }}
                            sx={{
                                fontSize: '10px',
                                '.MuiTablePagination-displayedRows': {
                                    fontSize: '10px',
                                },
                            }}
                        />
                        <SearchField
                            color="secondary"
                            variant="outlined"
                            defaultValue={search}
                            sx={{ maxWidth: '220px' }}
                            onSearchCallBack={setSearch}
                            placeholder={ENTER_OBJECT_NAME}
                            automation-id="recyclebin-search"
                            InputProps={{ sx: { maxHeight: '40px' } }}
                            userAction={UserActionWorkspace.WORKSPACE_OBJECTS_SEARCH}
                            userActionCategory={CategoriesWorkspace.WORKSPACE_OBJECTS_PAGE}
                            label={SEARCH_LABEL}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Stack>
                    <OrgObjectList
                        objects={gooseData?.objects ?? []}
                        isLoading={isFetching}
                        handleSort={handleSort}
                        fields={fields}
                    />
                </Stack>
            </PageContent>
        </Grid>
    );
}
